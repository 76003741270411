// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-claimpunks-index-tsx": () => import("./../../../src/pages/claimpunks/index.tsx" /* webpackChunkName: "component---src-pages-claimpunks-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mission-index-tsx": () => import("./../../../src/pages/mission/index.tsx" /* webpackChunkName: "component---src-pages-mission-index-tsx" */),
  "component---src-pages-mynoobs-index-tsx": () => import("./../../../src/pages/mynoobs/index.tsx" /* webpackChunkName: "component---src-pages-mynoobs-index-tsx" */),
  "component---src-pages-noobs-index-tsx": () => import("./../../../src/pages/noobs/index.tsx" /* webpackChunkName: "component---src-pages-noobs-index-tsx" */),
  "component---src-pages-punks-index-tsx": () => import("./../../../src/pages/punks/index.tsx" /* webpackChunkName: "component---src-pages-punks-index-tsx" */),
  "component---src-pages-terms-index-tsx": () => import("./../../../src/pages/terms/index.tsx" /* webpackChunkName: "component---src-pages-terms-index-tsx" */),
  "component---src-pages-zoo-index-tsx": () => import("./../../../src/pages/zoo/index.tsx" /* webpackChunkName: "component---src-pages-zoo-index-tsx" */)
}

